import Vue from "vue";
import Router from "vue-router";
import store from "./store";

// BRANCH: feature/31/LazyLoadingRoutes

const Login = () =>
  import(/* webpackChunkName: "auth" */ "@/components/auth/login.vue");
const Signup = () =>
  import(/* webpackChunkName: "auth" */ "@/components/auth/signup.vue");

const Complaints = () =>
  import(
    /* webpackChunkName: "complaints" */ "@/components/complaint/complaints.vue"
  );

const About = () =>
  import(/* webpackChunkName: "home" */ "@/components/about.vue");
const Contact = () =>
  import(/* webpackChunkName: "home" */ "@/components/contact.vue");
const ErrorPage = () =>
  import(/* webpackChunkName: "home" */ "@/components/error-page.vue");
const Home = () =>
  import(/* webpackChunkName: "home" */ "@/components/home.vue");

const Violations = () =>
  import(
    /* webpackChunkName: "parking" */ "@/components/violation/violations.vue"
  );

const Reports = () =>
  import(/* webpackChunkName: "reports" */ "@/components/reports/reports.vue");
const ReportOptions = () =>
  import(
    /* webpackChunkName: "reports" */ "@/components/reports/report-options.vue"
  );
const ViolationWeeklyReport = () =>
  import(
    /* webpackChunkName: "reports" */ "@/components/reports/violation-weekly-report.vue"
  );
const ComplaintWeeklyReport = () =>
  import(
    /* webpackChunkName: "reports" */ "@/components/reports/complaint-weekly-report.vue"
  );
const YardOrderWeeklyReport = () =>
  import(
    /* webpackChunkName: "reports" */ "@/components/reports/yardorder-weekly-report.vue"
  );
const GlobalSearch = () =>
  import(
    /* webpackChunkName: "reports" */ "@/components/reports/global-search.vue"
  );

const ColorScheme = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/color-scheme.vue"
  );
const CompanyLogo = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/company-logo.vue"
  );
  const ApiSettings = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/api-settings.vue"
  );
const ComplaintCategories = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/complaint-categories.vue"
  );
const ComplaintTypes = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/complaint-types.vue"
  );
const ModuleRolePermissions = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/module-role-permissions.vue"
  );
const Settings = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/settings.vue"
  );
const SettingOptions = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/setting-options.vue"
  );
const VehicleMakes = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/vehicle-makes.vue"
  );
const ViolationTypes = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/violation-types.vue"
  );
const YORequirements = () =>
  import(
    /* webpackChunkName: "settings" */ "@/components/settings/yo-requirements.vue"
  );

const YardOrders = () =>
  import(
    /* webpackChunkName: "yardorders" */ "@/components/yard/yard-orders.vue"
  );

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/login",
      component: Login,
      name: "login",
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/signup",
      component: Signup,
      name: "signup",
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/violations",
      name: "violations",
      component: Violations,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/complaints",
      name: "complaints",
      component: Complaints,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/yardorders",
      name: "yardorders",
      component: YardOrders,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/reports",
      component: Reports,
      beforeEnter: ifAuthenticated,
      children: [
        { path: "", name: "reportoptions", component: ReportOptions },
        {
          path: "violationWeeklyReport",
          name: "violationWeeklyReport",
          component: ViolationWeeklyReport,
        },
        {
          path: "complaintWeeklyReport",
          name: "complaintWeeklyReport",
          component: ComplaintWeeklyReport,
        },
        {
          path: "yardorderWeeklyReport",
          name: "yardorderWeeklyReport",
          component: YardOrderWeeklyReport,
        },
        {
          path: "globalSearch",
          name: "globalSearch",
          component: GlobalSearch,
        },
      ],
    },
    {
      path: "/about",
      name: "about",
      component: About,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/settings",
      component: Settings,
      beforeEnter: ifAuthenticated,
      children: [
        { path: "", name: "settingoptions", component: SettingOptions },
        {
          path: "violationTypes",
          name: "violationTypes",
          component: ViolationTypes,
        },
        {
          path: "vehicleMakes",
          name: "vehicleMakes",
          component: VehicleMakes,
        },
        {
          path: "complaintCategories",
          name: "complaintCategories",
          component: ComplaintCategories,
        },
        {
          path: "complaintTypes",
          name: "complaintTypes",
          component: ComplaintTypes,
        },
        {
          path: "yoRequirements",
          name: "yoRequirements",
          component: YORequirements,
        },
        {
          path: "moduleRolePermissions",
          name: "moduleRolePermissions",
          component: ModuleRolePermissions,
        },
        {
          path: "companyLogo",
          name: "companyLogo",
          component: CompanyLogo,
        },
        {
          path: "colorScheme",
          name: "colorScheme",
          component: ColorScheme,
        },
        {
          path: "apiSettings",
          name: "apiSettings",
          component: ApiSettings,
        },
      ],
    },
    {
      path: "*",
      component: ErrorPage,
      beforeEnter: ifAuthenticated,
    },
  ],
});
