export const parseList = (response) => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const parseItem = (response, code) => {
  if (response.status !== code) throw Error(response.message);
  let item = response.data;
  if (typeof item !== "object") {
    item = undefined;
  }
  return item;
};

export function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
}

export const reportStyles = {
  headerText: {
    fontSize: 8,
    italics: true,
    margin: [25, 0, 0, 0],
    alignment: "right",
  },
  footerText: {
    fontSize: 8,
    margin: [0, 0, 0, 10],
    alignment: "right",
  },
  heading: {
    fontSize: 12,
    bold: true,
    margin: [10, 10, 10, 0],
    alignment: "center",
  },
  subheading: {
    fontSize: 11,
    bold: true,
    margin: [0, 15, 0, 5],
  },
  subheading1: {
    italics: true,
    alignment: "center",
    fontSize: 8,
  },
  table: {
    margin: [0, 5, 0, 5],
  },
  tableHeader: {
    fontSize: 10,
  },
  tableBody: {
    fontSize: 8,
  },
};
