import * as axios from "axios";
import { API } from "./config";
import * as commonjs from "./common";

const getYardOrders = async function() {
  try {
    const response = await axios.get(`${API}/yardorders?_sort=dateIssued:desc`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getYOStatuses = async function() {
  try {
    const response = await axios.get(`${API}/yard-order-statuses`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addYardOrder = async function(yardOrder) {
  try {
    return await axios.post(`${API}/yardorders`, yardOrder);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateYOForAssignTo = async function(dataObj) {
  try {
    return await axios.post(`${API}/yardorders/assignTo`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editYardOrder = async function(yardOrder) {
  try {
    return await axios.put(`${API}/yardorders/${yardOrder.id}`, yardOrder);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteYardOrder = async function(yardOrderId) {
  try {
    return await axios.delete(`${API}/yardorders/${yardOrderId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const closeYardOrder = async function(dataObj) {
  try {
    return await axios.post(`${API}/yardorders/close`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateYOPhotos = async function(dataObj) {
  try {
    let formData = new FormData();
    for (let i = 0; i < dataObj.files.length; i++) {
      formData.append("files", dataObj.files[i]);
    }
    formData.append("ref", "yardorder");
    formData.append("refId", dataObj.refId);
    formData.append("field", "photos");

    return await axios.post(`${API}/upload`, formData);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteYOPhoto = async function(deleteYOId) {
  try {
    return await axios.delete(`${API}/upload/files/${deleteYOId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const yardOrderService = {
  getYardOrders,
  getYOStatuses,
  addYardOrder,
  updateYOForAssignTo,
  editYardOrder,
  deleteYardOrder,
  closeYardOrder,
  updateYOPhotos,
  deleteYOPhoto,
};
