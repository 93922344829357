<template>
  <v-card>
    <v-navigation-drawer
      fixed
      app
      v-model="drawer"
      :color="clrLeftNav"
      dark
      :clipped="$vuetify.breakpoint.mdAndUp"
      :width="170"
    >
      <v-list dense flat>
        <v-subheader>QUICK LINKS ...</v-subheader>
        <router-link
          v-for="routes in links"
          v-bind:key="routes.id"
          :to="`${routes.page}`"
        >
          <v-list-item class="tile pl-1 pr-0">
            <v-list-item-icon class="mr-1">
              <v-icon small v-text="routes.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ routes.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      fixed
      app
      dark
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      :style="
        `background: -webkit-linear-gradient(to right, ${getColorScheme.headerLight}, ${getColorScheme.headerDark}, ${getColorScheme.headerDarkest});
      background: linear-gradient(to right, ${getColorScheme.headerLight}, ${getColorScheme.headerDark}, ${getColorScheme.headerDarkest});`
      "
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <v-img
          :src="
            getCompanyLogo.logoImage == undefined
              ? ''
              : getCompanyLogo.logoImage.url
          "
          max-width="18vh"
          max-height="6vh"
          contain
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row>
        <v-col align="right" justify="center">
          Welcome {{ getUser.fullname }}
          <v-btn
            v-if="isAuthenticated"
            @click="logout"
            color="red"
            text
            small
            dark
            raised
          >
            Logout
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
  </v-card>
</template>
<script>
import * as constants from "../../shared/constants";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import * as VueGoogleMaps from "vue2-google-maps";
import GoogleMapCluster from "vue2-google-maps/dist/components/cluster";
import moment from "moment";

// Global variables
Vue.prototype.$moment = moment;

export default {
  name: "Navigation",
  data() {
    return {
      drawer: true,
      links: [],
      clrLeftNav: constants.CLR_LEFT_NAV,
    };
  },
  async created() {
    // Load google map first to solve refresh page issue
    await this.loadGoogleMapComponent();
    if (this.getUser == undefined || this.getUser == "") {
      this.logout();
      return;
    }
    this.drawer = true;
    this.filterLinks();
    await this.getColorSchemeAction();
    await this.getCompanyLogoAction();
    await this.getUserListAction();
  },
  methods: {
    ...mapActions([
      "authLogout",
      "getUserListAction",
      "getModuleRolePermissionAction",
      "getColorSchemeAction",
      "getCompanyLogoAction",
    ]),
    getLogoImageUrl() {
      return this.getCompanyLogo.logoImage == undefined
        ? ""
        : this.getCompanyLogo.logoImage.url;
    },
    async logout() {
      await this.authLogout();
      this.$router.push({ name: "login" });
    },
    async filterLinks() {
      await this.getModuleRolePermissionAction();
      //filter links based on role permissions
      for (const roleModule of this.getModuleRolePermissions) {
        if (
          roleModule.role.type == this.getUser.role.type &&
          roleModule.isVisible == true
        ) {
          this.links.push({
            text: roleModule.module.name,
            page: roleModule.module.route,
            icon: roleModule.module.icon,
            order: roleModule.module.order,
          });
        }
      }
      //sorting based on order number
      this.links.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );
    },
    async loadGoogleMapComponent() {
      // Google Maps
      Vue.use(VueGoogleMaps, {
        load: {
          key: this.getApiSettings.googleMapKey, //process.env.VUE_APP_GOOGLE_MAP_KEY,
          libraries: "places",
        },
        installComponents: false,
      });

      Vue.component("google-map", VueGoogleMaps.Map);
      Vue.component("google-map-marker", VueGoogleMaps.Marker);
      Vue.component("google-autocomplete", VueGoogleMaps.Autocomplete);
      Vue.component("google-cluster", GoogleMapCluster);
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getUser",
      "getModuleRolePermissions",
      "getModules",
      "getCompanyLogo",
      "getColorScheme",
      "getApiSettings",
    ]),
  },
};
</script>
<style scoped>
.tile {
  margin: 5px;
  border-radius: 4px;
}
.tile:hover {
  background: grey;
}
a {
  text-decoration: none;
}
</style>
