import * as axios from "axios";
import { API } from "./config";
import * as commonjs from "./common";

const getReportSearchResults = async function(searchText) {
  try {
    const response = await axios.post(`${API}/modules/search`, {
      searchText: searchText,
    });
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const reportService = {
  getReportSearchResults,
};
