import * as axios from "axios";
import { API } from "./config";
import * as commonjs from "./common";

const getComplaints = async function() {
  try {
    const response = await axios.get(
      `${API}/complaints?_sort=complaintDate:desc,complaintTime:desc`
    );
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getComplaintDetails = async function(complaintId) {
  try {
    const response = await axios.get(`${API}/complaints/${complaintId}`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addComplaint = async function(complaint) {
  try {
    return await axios.post(`${API}/complaints`, complaint);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateComplaintForAssignTo = async function(dataObj) {
  try {
    return await axios.post(`${API}/complaints/assignTo`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateComplaintToAddNotes = async function(dataObj) {
  try {
    return await axios.post(`${API}/investigativenotes`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateComplaintForUploadFile = async function(dataObj) {
  try {
    let formData = new FormData();
    for (let i = 0; i < dataObj.files.length; i++) {
      formData.append("files", dataObj.files[i]);
    }
    formData.append("ref", "investigativenote");
    formData.append("refId", dataObj.refId);
    formData.append("field", "docs");

    return await axios.post(`${API}/upload`, formData);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getUserList = async function() {
  try {
    const response = await axios.get(`${API}/users`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const deleteComplaint = async function(complaintId) {
  try {
    return await axios.delete(`${API}/complaints/${complaintId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteInvestigativeNote = async function(dataObj) {
  try {
    return await axios.post(`${API}/investigativenotes/deleteNote`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteInvestigativeDoc = async function(deleteMediaDocId) {
  try {
    return await axios.delete(`${API}/upload/files/${deleteMediaDocId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editInvestigativeNote = async function(dataObj) {
  try {
    return await axios.put(
      `${API}/investigativenotes/:${dataObj.editInvestigativeNoteId}`,
      dataObj
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

const closeComplaint = async function(dataObj) {
  try {
    return await axios.post(`${API}/complaints/close`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editComplaint = async function(complaint) {
  try {
    return await axios.put(`${API}/complaints/${complaint._id}`, complaint);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const complaintService = {
  getComplaints,
  getComplaintDetails,
  addComplaint,
  updateComplaintToAddNotes,
  updateComplaintForAssignTo,
  updateComplaintForUploadFile,
  getUserList,
  deleteComplaint,
  deleteInvestigativeNote,
  deleteInvestigativeDoc,
  editInvestigativeNote,
  closeComplaint,
  editComplaint,
};
