import * as axios from "axios";
import { API } from "./config";
import * as commonjs from "./common";

const getViolations = async function() {
  try {
    const response = await axios.get(
      `${API}/violations?_sort=dateIssued:desc,timeIssued:desc`
    );
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addViolation = async function(violation) {
  try {
    return await axios.post(`${API}/violations`, violation);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteViolation = async function(violationId) {
  try {
    return await axios.delete(`${API}/violations/${violationId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editViolation = async function(violation) {
  try {
    return await axios.put(`${API}/violations/${violation._id}`, violation);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const violationService = {
  getViolations,
  addViolation,
  deleteViolation,
  editViolation,
};