import * as axios from "axios";
import { API } from "./config";

const authenticateUser = async function(dataObj) {
  let authResponse = null;
  await axios
    .post(`${API}/auth/local`, {
      identifier: dataObj.username,
      password: dataObj.password,
    })
    .then((response) => {
      authResponse = {
        token: response.data.jwt,
        user: response.data.user,
      };
    })
    .catch((error) => {
      console.log("An error occurred:", error);
    });

  return authResponse;
};

const registerUser = async function(dataObj) {
  let userRegistered = false;
  await axios
    .post(`${API}/auth/local/register`, {
      fullname: dataObj.fullname,
      email: dataObj.email,
      username: dataObj.username,
      password: dataObj.password,
    })
    .then((response) => {
      userRegistered = true;
      console.log("User profile", response.data.user);
    })
    .catch((error) => {
      userRegistered = false;
      console.log("An error occurred:", error);
    });

  return userRegistered;
};

export const authService = {
  authenticateUser,
  registerUser,
};
