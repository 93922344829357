//Auth
export const AUTH_REQUEST = "authRequest";
export const AUTH_SUCCESS = "authSuccess";
export const AUTH_ERROR = "authError";
export const AUTH_LOGOUT = "authLogout";
export const AUTH_RESET = "authReset";
export const USER_REGISTER_SUCCESS = "userRegisterSuccess";
export const GET_USER_LIST = "getUserList";

//Violations
export const GET_VIOLATIONS = "getViolations";

//Complaints
export const GET_COMPLAINTS = "getComplaints";
export const UPDATE_COMPLAINT = "updateComplaint";
export const GET_COMPLAINT_CATEGORIES = "getComplaintCategories";
export const GET_COMPLAINT = "getComplaintDetails";

//Yard Orders
export const GET_YARD_ORDERS = "getYardOrders";
export const GET_YO_REQUIREMENTS = "getYORequirements";
export const GET_YO_STATUSES = "getYOStatuses";

//Reports
export const GET_REPORT_SEARCH_RESULTS = "getReportSearchResults";

//Settings
export const GET_VIOLATION_TYPES = "getViolationTypes";
export const GET_VEHICLE_MAKES = "getVehicleMakes";
export const GET_MODULE_ROLE_PERMISSIONS = "getModuleRolePermission";
export const GET_MODULES = "getModule";
export const GET_USER_ROLE_LIST = "getUserRoleList";
export const GET_COMPANY_LOGO = "getCompanyLogo";
export const GET_COLOR_SCHEME = "getColorScheme";
export const GET_API_SETTINGS = "getApiSettings";
