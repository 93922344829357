<template>
  <div id="app">
    <v-app>
      <v-main
        ><NavBar v-if="isAuthenticated" />
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <FooterBar v-if="isAuthenticated" />
    </v-app>
  </div>
</template>

<script>
import NavBar from "@/components/core/nav-bar";
import FooterBar from "@/components/core/footer-bar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { NavBar, FooterBar },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
<style>
.v-application {
  font-size: 0.9em !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  font-size: 0.8em !important;
}
.v-text-field label {
  font-size: 0.9em !important;
}
.v-input--switch label {
  font-size: 0.8em !important;
}
button {
  /*for button text*/
  text-transform: none !important;
}
</style>
