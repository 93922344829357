import * as axios from "axios";
import { API } from "./config";
import * as commonjs from "./common";

const getViolationTypes = async function() {
  try {
    const response = await axios.get(`${API}/violation-Types`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addViolationType = async function(violationType) {
  try {
    return await axios.post(`${API}/violation-Types`, violationType);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editViolationType = async function(violationType) {
  try {
    return await axios.put(
      `${API}/violation-Types/${violationType.id}`,
      violationType
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteViolationType = async function(deleteId) {
  try {
    return await axios.delete(`${API}/violation-Types/${deleteId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getVehicleMakes = async function() {
  try {
    const response = await axios.get(`${API}/vehicle-makes?_sort=name:asc`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addVehicleMake = async function(vehicleMake) {
  try {
    return await axios.post(`${API}/vehicle-makes`, vehicleMake);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editVehicleMake = async function(vehicleMake) {
  try {
    return await axios.put(
      `${API}/vehicle-makes/${vehicleMake.id}`,
      vehicleMake
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteVehicleMake = async function(deleteId) {
  try {
    return await axios.delete(`${API}/vehicle-makes/${deleteId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getModuleRolePermissions = async function() {
  try {
    const response = await axios.get(
      `${API}/rolepermissions?_sort=role:asc,module:asc`
    );
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getComplaintCategories = async function() {
  try {
    const response = await axios.get(`${API}/complaint-categories`);

    let categoriesWithComplaints = commonjs.parseList(response);
    let categories = categoriesWithComplaints.map((category) => {
      return {
        id: category.id,
        category: category.category,
        types: category.complaint_types,
      };
    });
    return categories;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addComplaintCategory = async function(category) {
  try {
    return await axios.post(`${API}/complaint-categories`, category);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editComplaintCategory = async function(category) {
  try {
    return await axios.put(
      `${API}/complaint-categories/${category.id}`,
      category
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteComplaintCategory = async function(deleteId) {
  try {
    return await axios.delete(`${API}/complaint-categories/${deleteId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const addComplaintType = async function(complaintType) {
  try {
    return await axios.post(`${API}/complaint-types`, complaintType);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editComplaintType = async function(dataObj) {
  try {
    return await axios.put(`${API}/complaint-types/${dataObj.id}`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteComplaintType = async function(deleteId) {
  try {
    return await axios.delete(`${API}/complaint-types/${deleteId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getYORequirements = async function() {
  try {
    const response = await axios.get(`${API}/yard-requirements`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addYORequirement = async function(requirement) {
  try {
    return await axios.post(`${API}/yard-requirements`, requirement);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const editYORequirement = async function(dataObj) {
  try {
    return await axios.put(`${API}/yard-requirements/${dataObj.id}`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteYORequirement = async function(deleteId) {
  try {
    return await axios.delete(`${API}/yard-requirements/${deleteId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getModules = async function() {
  try {
    const response = await axios.get(`${API}/modules?_sort=module:asc`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getUserRoleList = async function() {
  try {
    const response = await axios.get(`${API}/users-permissions/roles`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const addModuleRolePermission = async function(rolePermission) {
  try {
    return await axios.post(`${API}/rolepermissions`, rolePermission);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateAllPermissions = async function(rolePermission) {
  try {
    return await axios.post(
      `${API}/rolepermissions/updateAllPermissions`,
      rolePermission
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getCompanyLogo = async function() {
  try {
    const response = await axios.get(`${API}/company-logo`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const uploadCompanyLogo = async function(dataObj) {
  try {
    let formData = new FormData();
    formData.append("files", dataObj.file);
    formData.append("ref", "company-logo");
    formData.append("refId", dataObj.refId);
    formData.append("field", "logoImage");

    return await axios.post(`${API}/upload`, formData);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getColorScheme = async function() {
  try {
    const response = await axios.get(`${API}/color-scheme`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const editColorScheme = async function(dataObj) {
  try {
    return await axios.put(`${API}/color-scheme`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getApiSettings = async function() {
  try {
    const response = await axios.get(`${API}/api-settings`);
    return commonjs.parseList(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const editApiSettings = async function(dataObj) {
  try {
    return await axios.put(`${API}/api-settings`, dataObj);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const settingsService = {
  getViolationTypes,
  addViolationType,
  editViolationType,
  deleteViolationType,
  getVehicleMakes,
  addVehicleMake,
  editVehicleMake,
  deleteVehicleMake,
  getComplaintCategories,
  addComplaintCategory,
  editComplaintCategory,
  deleteComplaintCategory,
  addComplaintType,
  editComplaintType,
  deleteComplaintType,
  getYORequirements,
  addYORequirement,
  editYORequirement,
  deleteYORequirement,
  getModuleRolePermissions,
  getModules,
  getUserRoleList,
  addModuleRolePermission,
  updateAllPermissions,
  getCompanyLogo,
  uploadCompanyLogo,
  getColorScheme,
  editColorScheme,
  getApiSettings,
  editApiSettings,
};
