<template>
  <v-footer dark padless app>
    <v-card class="flex" flat tile :color="clrFooter">
      <v-card-title
        class="body-2 py-0 text-center teal--text"
        :style="`background-color:${getColorScheme.footerBgColor};`"
      >
        .
      </v-card-title>
    </v-card>
  </v-footer>
</template>
<script>
import * as constants from "../../shared/constants";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    icons: [
      "fab fa-facebook",
      "fab fa-twitter",
      "fab fa-google-plus",
      "fab fa-linkedin",
      "fab fa-instagram",
    ],
    clrFooter: constants.CLR_FOOTER,
  }),
  computed: {
    ...mapGetters(["getColorScheme"]),
  },
};
</script>
