import Vue from "vue";
import Vuex from "vuex";
import * as axios from "axios";
import {
  authService,
  violationService,
  complaintService,
  yardOrderService,
  reportService,
  settingsService,
} from "../shared";
import router from "../router";
import createPersistedState from "vuex-persistedstate";
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_RESET,
  USER_REGISTER_SUCCESS,
  GET_USER_LIST,
  GET_VIOLATIONS,
  GET_COMPLAINTS,
  GET_COMPLAINT,
  UPDATE_COMPLAINT,
  GET_COMPLAINT_CATEGORIES,
  GET_YARD_ORDERS,
  GET_YO_REQUIREMENTS,
  GET_YO_STATUSES,
  GET_REPORT_SEARCH_RESULTS,
  GET_VIOLATION_TYPES,
  GET_VEHICLE_MAKES,
  GET_MODULE_ROLE_PERMISSIONS,
  GET_MODULES,
  GET_USER_ROLE_LIST,
  GET_COMPANY_LOGO,
  GET_COLOR_SCHEME,
  GET_API_SETTINGS,
} from "./mutation-types";

Vue.use(Vuex);

//setting token for all axios calls (Required in case of page reload)
if (localStorage.getItem("user-token")) {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem(
    "user-token"
  );
}

const getDefaultState = () => ({
  //Auth
  token: localStorage.getItem("user-token") || "",
  status: "",
  user: {},
  userRegistrationSuccess: false,
  userList: [],

  //Violation
  violations: [],

  //Complaint
  complaints: [],
  selectedComplaint: null,
  complaintCategories: [],

  //Yard Orders
  yardOrders: [],
  yoRequirements: [],

  //Reports
  reportSearchResults: [],

  //Settings
  violationTypes: [],
  vehicleMakes: [],
  moduleRolePermissions: [],
  modules: [],
  userRoleList: [],
  colorScheme: {},
  companyLogo: "",
  apiSettings: {},

  error: "",
});

const mutations = {
  //Auth
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    state.user = resp.user;
  },
  [AUTH_ERROR]: (state) => {
    state.status = "error";
    state.user = null;
  },
  [AUTH_LOGOUT]: (state) => {
    Object.assign(state, getDefaultState());
  },
  [AUTH_RESET]: (state) => {
    state.status = "";
    state.token = "";
    state.user = null;
    state.userRegistrationSuccess = false;
  },
  [USER_REGISTER_SUCCESS]: (state) => {
    state.userRegistrationSuccess = true;
  },
  //Violations
  [GET_VIOLATIONS](state, violations) {
    state.violations = violations;
  },

  //Complaints
  [GET_COMPLAINTS](state, complaints) {
    state.complaints = complaints;
  },
  [GET_COMPLAINT](state, complaint) {
    state.selectedComplaint = complaint;
  },
  [UPDATE_COMPLAINT](state, complaint) {
    const index = state.complaints.findIndex(
      (h) => h.complaintNumber === complaint.complaintNumber
    );
    state.complaints.splice(index, 1, complaint);
    state.complaints = [...state.complaints];
  },
  [GET_COMPLAINT_CATEGORIES](state, categories) {
    state.complaintCategories = categories;
  },
  [GET_USER_LIST](state, users) {
    state.userList = users;
  },

  //Yard Orders
  [GET_YARD_ORDERS](state, yardOrders) {
    state.yardOrders = yardOrders;
  },
  [GET_YO_REQUIREMENTS](state, yoRequirements) {
    state.yoRequirements = yoRequirements;
  },
  [GET_YO_STATUSES](state, yoStatuses) {
    state.yoStatuses = yoStatuses;
  },

  //Reports
  [GET_REPORT_SEARCH_RESULTS](state, searchResults) {
    state.reportSearchResults = searchResults;
  },

  //Settings
  [GET_VIOLATION_TYPES](state, violationTypes) {
    state.violationTypes = violationTypes;
  },
  [GET_VEHICLE_MAKES](state, vehicleMakes) {
    state.vehicleMakes = vehicleMakes;
  },
  [GET_MODULE_ROLE_PERMISSIONS](state, moduleRolePermissions) {
    state.moduleRolePermissions = moduleRolePermissions;
  },
  [GET_MODULES](state, modules) {
    state.modules = modules;
  },
  [GET_USER_ROLE_LIST](state, roles) {
    state.userRoleList = roles;
  },
  [GET_COMPANY_LOGO](state, companyLogo) {
    state.companyLogo = companyLogo;
  },
  [GET_COLOR_SCHEME](state, colorScheme) {
    state.colorScheme = colorScheme;
  },
  [GET_API_SETTINGS](state, apiSettings) {
    state.apiSettings = apiSettings;
  },
};

const actions = {
  //Auth
  async authRequest({ commit }, dataObj) {
    try {
      const authResponse = await authService.authenticateUser(dataObj);
      if (authResponse != null) {
        localStorage.setItem("user-token", `Bearer ${authResponse.token}`);
        localStorage.setItem("user-token-saved-time", new Date().getTime());

        //setting token for all axios calls
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${authResponse.token}`;
        commit(AUTH_SUCCESS, authResponse);
      } else {
        commit(AUTH_ERROR);
      }
    } catch (error) {
      commit(AUTH_ERROR);
    }
  },
  async authLogout({ commit }) {
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-token-saved-time");
    delete axios.defaults.headers.common["Authorization"];
    commit(AUTH_LOGOUT);
  },
  async authReset({ commit }) {
    commit(AUTH_RESET);
  },
  async registerUser({ commit }, dataObj) {
    const userRegistered = await authService.registerUser(dataObj);
    if (userRegistered) {
      commit(USER_REGISTER_SUCCESS);
    }
  },
  async getUserListAction({ commit }) {
    const users = await complaintService.getUserList();
    commit(GET_USER_LIST, users);
  },

  //Violations
  async addViolationAction({ dispatch }, violation) {
    const response = await violationService.addViolation(violation);
    if (response != null) {
      if (response.data.error != undefined) {
        this.state.error = response.data.error;
      } else {
        await dispatch("getViolationsAction");
      }
    }
  },
  async getViolationsAction({ commit }) {
    const violations = await violationService.getViolations();
    commit(GET_VIOLATIONS, violations);
  },
  async deleteViolationAction({ dispatch }, violationId) {
    const response = await violationService.deleteViolation(violationId);
    if (response != null) {
      await dispatch("getViolationsAction");
    }
  },
  async editViolationAction({ dispatch }, violation) {
    const response = await violationService.editViolation(violation);
    if (response != null) {
      await dispatch("getViolationsAction");
    }
  },
  //Complaints
  async addComplaintAction({ dispatch }, complaint) {
    const response = await complaintService.addComplaint(complaint);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async getComplaintsAction({ commit }) {
    const complaints = await complaintService.getComplaints();
    commit(GET_COMPLAINTS, complaints);
  },
  async getComplaintDetailsAction({ commit }, complaintId) {
    const complaint = await complaintService.getComplaintDetails(complaintId);
    commit(GET_COMPLAINT, complaint);
  },
  async updateComplaintToAddNotesAction({ dispatch }, dataObj) {
    const response = await complaintService.updateComplaintToAddNotes(dataObj);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async updateComplaintForAssignToAction({ dispatch }, dataObj) {
    const response = await complaintService.updateComplaintForAssignTo(dataObj);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async updateComplaintForUploadFileAction({ dispatch }, dataObj) {
    const responseAddNotes = await complaintService.updateComplaintToAddNotes({
      complaint: dataObj.complaintId,
      complaintStatus: dataObj.complaintStatus,
      note: dataObj.note,
    });

    if (dataObj.files != null) {
      await complaintService.updateComplaintForUploadFile({
        files: dataObj.files,
        refId: responseAddNotes.data.id,
      });
    }
    if (responseAddNotes != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async deleteComplaintAction({ dispatch }, complaintId) {
    const response = await complaintService.deleteComplaint(complaintId);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async deleteInvestigativeNoteAction({ dispatch }, dataObj) {
    const response = await complaintService.deleteInvestigativeNote(dataObj);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async deleteInvestigativeDocAction({ dispatch }, deleteMediaDocId) {
    const response = await complaintService.deleteInvestigativeDoc(deleteMediaDocId);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async editInvestigativeNoteAction({ dispatch }, dataObj) {
    const response = await complaintService.editInvestigativeNote(dataObj);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async closeComplaintAction({ dispatch }, dataObj) {
    const response = await complaintService.closeComplaint(dataObj);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },
  async editComplaintAction({ dispatch }, complaint) {
    const response = await complaintService.editComplaint(complaint);
    if (response != null) {
      await dispatch("getComplaintsAction");
    }
  },

  //Yard Orders
  async addYardOrderAction({ dispatch }, yardOrder) {
    const response = await yardOrderService.addYardOrder(yardOrder);
    if (response != null) {
      if (response.data.error != undefined) {
        this.state.error = response.data.error;
      } else {
        await dispatch("getYardOrdersAction");
      }
    }
  },
  async getYardOrdersAction({ commit }) {
    const yardOrders = await yardOrderService.getYardOrders();
    commit(GET_YARD_ORDERS, yardOrders);
  },
  async getYOStatusesAction({ commit }) {
    const yoStatuses = await yardOrderService.getYOStatuses();
    commit(GET_YO_STATUSES, yoStatuses);
  },
  async updateYOForAssignToAction({ dispatch }, dataObj) {
    const response = await yardOrderService.updateYOForAssignTo(dataObj);
    if (response != null) {
      await dispatch("getYardOrdersAction");
    }
  },
  async updateYOPhotosAction({ dispatch }, dataObj) {
    if (dataObj.files != null) {
      const response = await yardOrderService.updateYOPhotos({
        files: dataObj.files,
        refId: dataObj.yardOrderId,
      });
      if (response != null) {
        await dispatch("getYardOrdersAction");
      }
    }
  },
  async deleteYOPhotoAction({ dispatch }, deleteYOId) {
    const response = await yardOrderService.deleteYOPhoto(deleteYOId);
    if (response != null) {
      await dispatch("getYardOrdersAction");
    }
  },
  async editYardOrderAction({ dispatch }, yardOrder) {
    const response = await yardOrderService.editYardOrder(yardOrder);
    if (response != null) {
      await dispatch("getYardOrdersAction");
    }
  },
  async deleteYardOrderAction({ dispatch }, yardOrderId) {
    const response = await yardOrderService.deleteYardOrder(yardOrderId);
    if (response != null) {
      await dispatch("getYardOrdersAction");
    }
  },
  async closeYardOrderAction({ dispatch }, dataObj) {
    const response = await yardOrderService.closeYardOrder(dataObj);
    if (response != null) {
      await dispatch("getYardOrdersAction");
    }
  },

  //Reports
  async getReportSearchResultsAction({ commit }, searchText) {
    const results = await reportService.getReportSearchResults(searchText);
    commit(GET_REPORT_SEARCH_RESULTS, results);
  },

  //Settings
  async getViolationTypesAction({ commit }) {
    const violationTypes = await settingsService.getViolationTypes();
    commit(GET_VIOLATION_TYPES, violationTypes);
  },
  async addViolationTypeAction({ dispatch }, violationType) {
    const response = await settingsService.addViolationType(violationType);
    if (response != null) {
      await dispatch("getViolationTypesAction");
    }
  },
  async editViolationTypeAction({ dispatch }, violationType) {
    const response = await settingsService.editViolationType(violationType);
    if (response != null) {
      await dispatch("getViolationTypesAction");
    }
  },
  async deleteViolationTypeAction({ dispatch }, deleteId) {
    const response = await settingsService.deleteViolationType(deleteId);
    if (response != null) {
      await dispatch("getViolationTypesAction");
    }
  },
  async getVehicleMakesAction({ commit }) {
    const vehicleMakes = await settingsService.getVehicleMakes();
    commit(GET_VEHICLE_MAKES, vehicleMakes);
  },
  async addVehicleMakeAction({ dispatch }, vehicleMake) {
    const response = await settingsService.addVehicleMake(vehicleMake);
    if (response != null) {
      await dispatch("getVehicleMakesAction");
    }
  },
  async editVehicleMakeAction({ dispatch }, vehicleMake) {
    const response = await settingsService.editVehicleMake(vehicleMake);
    if (response != null) {
      await dispatch("getVehicleMakesAction");
    }
  },
  async deleteVehicleMakeAction({ dispatch }, deleteId) {
    const response = await settingsService.deleteVehicleMake(deleteId);
    if (response != null) {
      await dispatch("getVehicleMakesAction");
    }
  },
  async getComplaintCategoriesAction({ commit }) {
    const complaintCategories = await settingsService.getComplaintCategories();
    commit(GET_COMPLAINT_CATEGORIES, complaintCategories);
  },
  async addComplaintCategoryAction({ dispatch }, category) {
    const response = await settingsService.addComplaintCategory(category);
    if (response != null) {
      await dispatch("getComplaintCategoriesAction");
    }
  },
  async editComplaintCategoryAction({ dispatch }, category) {
    const response = await settingsService.editComplaintCategory(category);
    if (response != null) {
      await dispatch("getComplaintCategoriesAction");
    }
  },
  async deleteComplaintCategoryAction({ dispatch }, deleteId) {
    const response = await settingsService.deleteComplaintCategory(deleteId);
    if (response != null) {
      await dispatch("getComplaintCategoriesAction");
    }
  },
  async addComplaintTypeAction({ dispatch }, complaintType) {
    const response = await settingsService.addComplaintType(complaintType);
    if (response != null) {
      await dispatch("getComplaintCategoriesAction");
    }
  },
  async editComplaintTypeAction({ dispatch }, dataObj) {
    const response = await settingsService.editComplaintType(dataObj);
    if (response != null) {
      await dispatch("getComplaintCategoriesAction");
    }
  },
  async deleteComplaintTypeAction({ dispatch }, deleteComplaintType) {
    const response = await settingsService.deleteComplaintType(
      deleteComplaintType
    );
    if (response != null) {
      await dispatch("getComplaintCategoriesAction");
    }
  },

  async getYORequirementsAction({ commit }) {
    const yoRequirements = await settingsService.getYORequirements();
    commit(GET_YO_REQUIREMENTS, yoRequirements);
  },
  async addYORequirementAction({ dispatch }, requirement) {
    const response = await settingsService.addYORequirement(requirement);
    if (response != null) {
      await dispatch("getYORequirementsAction");
    }
  },
  async editYORequirementAction({ dispatch }, dataObj) {
    const response = await settingsService.editYORequirement(dataObj);
    if (response != null) {
      await dispatch("getYORequirementsAction");
    }
  },
  async deleteYORequirementAction({ dispatch }, deleteReq) {
    const response = await settingsService.deleteYORequirement(deleteReq);
    if (response != null) {
      await dispatch("getYORequirementsAction");
    }
  },

  async getModuleRolePermissionAction({ commit }) {
    const rolePermissions = await settingsService.getModuleRolePermissions();
    commit(GET_MODULE_ROLE_PERMISSIONS, rolePermissions);
  },
  async getModuleAction({ commit }) {
    const modules = await settingsService.getModules();
    commit(GET_MODULES, modules);
  },
  async getUserRoleListAction({ commit }) {
    const roles = await settingsService.getUserRoleList();
    commit(GET_USER_ROLE_LIST, roles);
  },
  async addModuleRolePermissionAction({ dispatch }, rolePermission) {
    const response = await settingsService.addModuleRolePermission(
      rolePermission
    );
    if (response != null) {
      await dispatch("getModuleRolePermissionAction");
    }
  },
  async updateAllPermissionsAction({ dispatch }, rolePermissions) {
    const response = await settingsService.updateAllPermissions(
      rolePermissions
    );
    if (response != null) {
      await dispatch("getModuleRolePermissionAction");
    }
  },
  async getCompanyLogoAction({ commit }) {
    const companyLogo = await settingsService.getCompanyLogo();
    commit(GET_COMPANY_LOGO, companyLogo);
  },
  async uploadCompanyLogoAction({ dispatch }, dataObj) {
    const response = await settingsService.uploadCompanyLogo(dataObj);
    if (response != null) {
      await dispatch("getCompanyLogoAction");
    }
  },
  async getColorSchemeAction({ commit }) {
    const colorScheme = await settingsService.getColorScheme();
    commit(GET_COLOR_SCHEME, colorScheme);
  },
  async editColorSchemeAction({ dispatch }, dataObj) {
    const response = await settingsService.editColorScheme(dataObj);
    if (response != null) {
      await dispatch("getColorSchemeAction");
    }
  },
  async getApiSettingsAction({ commit }) {
    const apiSettings = await settingsService.getApiSettings();
    commit(GET_API_SETTINGS, apiSettings);
  },
  async editApiSettingsAction({ dispatch }, dataObj) {
    const response = await settingsService.editApiSettings(dataObj);
    if (response != null) {
      await dispatch("getApiSettingsAction");
    }
  },
};

const getters = {
  // Auth
  isAuthenticated: (state) => !!state.token,
  getAuthStatus: (state) => state.status,
  getUser: (state) => {
    return state.user;
  },
  getUserRegisterationStatus: (state) => state.userRegistrationSuccess,
  getUserList: (state) => state.userList,

  //Complaints
  getComplaintCategories: (state) => state.complaintCategories,

  //Yard Orders
  getYORequirements: (state) => state.yoRequirements,
  getYOStatuses: (state) => state.yoStatuses,

  //Settings
  getViolationTypes: (state) => state.violationTypes,
  getVehicleMakes: (state) => state.vehicleMakes,
  getModuleRolePermissions: (state) => state.moduleRolePermissions,
  getModules: (state) => state.modules,
  getUserRoleList: (state) => state.userRoleList,
  getCompanyLogo: (state) => state.companyLogo,
  getColorScheme: (state) => state.colorScheme,
  getApiSettings: (state) => state.apiSettings,

  //common
  getError: (state) => state.error,
};

export default new Vuex.Store({
  //strict: process.env.NODE_ENV !== 'production',
  plugins: [
    (store) => {
      store.subscribeAction((action, state) => {
        let hours = 2;
        let saved = localStorage.getItem("user-token-saved-time");
        if (saved && new Date().getTime() - saved > hours * 60 * 60 * 1000) {
          localStorage.removeItem("user-token");
          localStorage.removeItem("user-token-saved-time");
          state.token = "";
          console.log(`User Token expires after ${hours} hours`);
          router.push({ name: "login" });
        }
      });
    },
    createPersistedState(),
  ],

  state: getDefaultState(),
  mutations,
  actions,
  getters,
});
